import React, { memo } from 'react';
import { ViewStyle, TextStyle } from 'react-native';

import { StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';

import Text from '../Text';

export interface ButtonProps {
  to?: string;
  linkProps?: TYPEFIXME;
  label: string;
  labelTheme?: string;
  width?: number | string;
  color?: string;
  style?: ViewStyle | Array<ViewStyle>;
  labelStyle?: TextStyle;
  onPress?: () => void;
}

const ButtonWithText: React.FC<ButtonProps> = memo(
  ({
    label,
    labelTheme = 'h4',
    width = undefined,
    color = undefined,
    style,
    labelStyle,
    ...rest
  }) => {
    return (
      <ButtonWrapper
        style={[
          s.button,
          {
            width,
            backgroundColor: color,
            borderColor: color || 'rgba(0, 0, 0, 0)',
          },
          style,
        ]}
        {...rest}
      >
        {() => (
          <Text theme={labelTheme} style={[s.label, labelStyle]}>
            {label}
          </Text>
        )}
      </ButtonWrapper>
    );
  },
);

export default ButtonWithText;

const s = StyleSheet.create({
  button: {
    ...cStyles.bwam,
    ...cStyles.phxl,
    ...cStyles.brs,
    paddingTop: 9,
    paddingBottom: 9,
  },
  label: cStyles.tac,
});
