import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { DrawerProvider } from '@dkrm/general-libs/Context/DrawerContext';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { View } from '@dkrm/ui-kit-basic';
import { ModalRadioButton } from '@dkrm/ui-kit-basic/v2';

import DrawerMenu from 'app-libs/components/DrawerMenu';
import { withError } from 'app-libs/components/ErrorBoundary';
import { DrawerLockContext } from 'app-libs/contexts/DrawerLockContext';
import { isDnBPage } from 'app-libs/etc/routeHelper';
import {
  checkInStoreLocation,
  load as loadAuth,
  loadMarketplaceProfile,
  loadShopAssistantCurrentStoreLocation,
  loadStoreLocationChoices,
  logout,
} from 'app-libs/redux_modules/auth';
import { headBasketAndLoadIfExist } from 'app-libs/redux_modules/entity_modules/basket';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import {
  getAuthMarketplaceProfile,
  getAuthUser,
  getIsShopAssistant,
} from 'app-libs/redux_modules/selectors/auth';
import { trackIdentifyUser } from 'app-libs/trackers';

import { AppBanner, AppBannerProvider } from 'components/AppBanner';
import LogoutConfirmationModal from 'components/account/components/LogoutConfirmationModal';
import WelcomeVoucherModal from 'components/voucher/WelcomeVoucherModal';

const K_DEFAULT_PAGE_WIDTH = 375;
const K_LOCKED_DRAWER_STATE = 'locked-closed';

@withError
@withRouter
@connect((state) => ({
  pageWidth: state.dimension.width || K_DEFAULT_PAGE_WIDTH,
  marketplaceProfile: getAuthMarketplaceProfile(state),
  isShopAssistant: getIsShopAssistant(state),
  user: getAuthUser(state),
}))
export default class DrawerContainer extends Component {
  constructor(props) {
    super(props);
    this.drawerRef = React.createRef();
    this.state = { showModal: false, choices: [], title: '' };
  }

  async componentDidMount() {
    const { dispatch, marketplaceProfile } = this.props;

    if (!isEmpty(marketplaceProfile)) {
      this.actionLoadShopAssistantStoreLocationChoices();
      return null;
    }

    await dispatch(loadAuth());
    await dispatch(loadMarketplaceProfile());
    this.actionLoadShopAssistantStoreLocationChoices();
    return null;
  }

  componentDidUpdate(prevProps) {
    const { dispatch, marketplaceProfile, user, isShopAssistant } = this.props;

    if (isEmpty(prevProps.marketplaceProfile) && !isEmpty(marketplaceProfile)) {
      if (isShopAssistant) {
        dispatch(loadShopAssistantCurrentStoreLocation());
      }
    }

    if (
      isEmpty(prevProps.marketplaceProfile?.currentStoreLocation) &&
      !isEmpty(marketplaceProfile?.currentStoreLocation)
    ) {
      this.actionLoadShopAssistantStoreLocationChoices();
    }

    if (isEmpty(prevProps.user) && !isEmpty(user)) {
      trackIdentifyUser(user);
    }
  }

  actionLoadShopAssistantStoreLocationChoices = async () => {
    const { dispatch, marketplaceProfile, isShopAssistant } = this.props;

    const shouldLoadStoreLocationChoices =
      isShopAssistant &&
      !isEmpty(marketplaceProfile?.currentStoreLocation) &&
      !marketplaceProfile?.currentStoreLocation?.code;
    if (shouldLoadStoreLocationChoices) {
      await dispatch(
        loadStoreLocationChoices((choices) => {
          this.setState({
            showModal: true,
            choices,
            title: marketplaceProfile?.name || '',
          });
        }),
      );
    }
    return null;
  };

  actionSubmitModal = async (value) => {
    const { dispatch } = this.props;
    if (value) {
      await dispatch(checkInStoreLocation(value));
      dispatch(dontShowPromptOnError(headBasketAndLoadIfExist()));
      this.setState({ showModal: false });
    }
  };

  actionCloseModal = () => {
    const { dispatch } = this.props;
    dispatch(logout());
    this.setState({ showModal: false });
  };

  renderNavigationView = () => {
    const { DrawerComponent } = this.props;
    return <DrawerComponent drawerRef={this.drawerRef} />;
  };

  render() {
    const {
      pageWidth,
      children,
      location: { pathname },
    } = this.props;
    const { showModal, choices, title } = this.state;

    return (
      <DrawerLockContext.Consumer>
        {({ shouldLockDrawer }) => {
          const drawerLockModeProps = shouldLockDrawer
            ? { drawerLockMode: K_LOCKED_DRAWER_STATE }
            : {};
          return (
            <DrawerProvider
              ref={this.drawerRef}
              drawerProps={{
                renderNavigationView: this.renderNavigationView,
                drawerWidth: pageWidth * 0.8,
                ...drawerLockModeProps,
              }}
            >
              <AppBannerProvider>
                {pathname !== '/' && !isDnBPage(pathname) && <AppBanner />}
                <ModalRadioButton
                  title={title}
                  showModal={showModal}
                  handleSubmit={this.actionSubmitModal}
                  actionOnCloseModal={this.actionCloseModal}
                  choices={choices}
                />
                <WelcomeVoucherModal />
                <LogoutConfirmationModal />
                {children}
              </AppBannerProvider>
              <View style={{ height: '0.1mm' }} />
            </DrawerProvider>
          );
        }}
      </DrawerLockContext.Consumer>
    );
  }
}

DrawerContainer.propTypes = {
  user: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
  isShopAssistant: PropTypes.bool.isRequired,
  pageWidth: PropTypes.number.isRequired,
  marketplaceProfile: PropTypes.shape().isRequired,

  location: PropTypes.shape().isRequired,

  dispatch: PropTypes.func.isRequired,

  DrawerComponent: PropTypes.node,
};

DrawerContainer.defaultProps = {
  DrawerComponent: DrawerMenu,
};
