import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showLogoutModal } from 'app-libs/redux_modules/auth';
import { User } from 'app-libs/redux_modules/auth/types';
import {
  isMemberLoaded as getIsMemberLoaded,
  getMemberUser,
} from 'app-libs/redux_modules/entity_modules/selectors/membership';
import {
  getAuthUser,
  isCurrentUserWorkshop as getIsCurrentUserWorkshop,
  isLoggedIn as getIsLoggedIn,
} from 'app-libs/redux_modules/selectors/auth';

import { useLogout } from 'components/account/utils';

import { K_ROUTE_MEMBERSHIP_DASHBOARD_PRO } from 'constants/routeConstants';

import {
  K_BASE_DRAWER_FOOTER_ITEMS,
  K_MEMBERSHIP_DRAWER_FOOTER_ITEMS,
  K_ROLE_WORKSHOP_DRAWER_FOOTER_ITEMS,
} from '../constants';

const K_MEMBERSHIP_TYPE_PRO = 'Pro';

export const useFooterItems = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isCurrentUserWorkshop = useSelector(getIsCurrentUserWorkshop);
  const member: TYPEFIXME = useSelector(getMemberUser);
  const isMemberLoaded = useSelector(getIsMemberLoaded);
  const user: User = useSelector(getAuthUser);

  const { actionLogout } = useLogout();

  const handleLogout = useCallback(() => {
    if (user.unmUserId) {
      dispatch(showLogoutModal());
    } else {
      actionLogout();
    }
  }, [actionLogout, dispatch, user.unmUserId]);

  const footerItems = useMemo(() => {
    const items: TYPEFIXME[] = [...K_BASE_DRAWER_FOOTER_ITEMS];

    if (isLoggedIn) {
      items.push({
        label: 'Keluar',
        onPress: handleLogout,
      });
    }

    if (isCurrentUserWorkshop) {
      items.unshift(...K_ROLE_WORKSHOP_DRAWER_FOOTER_ITEMS);
    }

    if (
      isMemberLoaded &&
      member &&
      Object.keys(member).length > 0 &&
      member?.membershipInfo?.type === K_MEMBERSHIP_TYPE_PRO
    ) {
      items.unshift({
        label: 'Dashboard Membership',
        linkUrl: K_ROUTE_MEMBERSHIP_DASHBOARD_PRO,
      });
    }

    items.unshift(...K_MEMBERSHIP_DRAWER_FOOTER_ITEMS);

    return items;
  }, [handleLogout, isCurrentUserWorkshop, isLoggedIn, isMemberLoaded, member]);

  return footerItems;
};
