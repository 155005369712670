import { Colors } from '@dkrm/ui-kit-basic';

const colors = {
  ...Colors,

  C_BLACK_100: '#010101',
  C_BLACK_80: '#474748',
  C_BLACK_60: '#747B7E',
  C_BLACK_40: '#ACB0B4',
  C_BLACK_30: '#C9D2DD',
  C_BLACK_20: '#DFE4EA',
  C_BLACK_10: '#EFF2F5',
  C_BLACK_0: '#FFFFFF',

  C_YELLOW_20: '#FCE8C4',
  C_YELLOW_40: '#F9D28A',
  C_YELLOW_60: '#F6BB4F',
  C_YELLOW_80: '#A47D35',

  C_TEAL_60: '#00B1BB',
  C_TEAL_80: '#00767D',
  C_TEAL_100: '#003B3E',

  C_DARK_TEAL_20: '#AAB8B8',
  C_DARK_TEAL_60: '#2B6363',

  C_RED_60: '#EC504F',
};

export default colors;
