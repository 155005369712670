import React, { memo } from 'react';

import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

const K_APP_PLATFORMS = ['ios', 'android'];

const AppVersion: React.FC = memo(() => {
  const platform = getPlatform();
  let appVersion = '';
  if (K_APP_PLATFORMS.includes(platform)) {
    appVersion = window?.__APP_VERSION__ || '';
  }

  if (!appVersion) {
    return null;
  }

  return (
    <View style={s.container}>
      <Text theme="body4-black30">Versi {appVersion}</Text>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.plxxl,
    ...cStyles.pbxxl,
    ...cStyles.ptm,
    ...cStyles.flex1,
    alignItems: 'start',
    justifyContent: 'flex-end',
  },
});

export default AppVersion;
