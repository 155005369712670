import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyleSheet } from '@dkrm/ui-kit-basic';
import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';

import { Voucher as IVoucher } from 'app-libs/components/campaign/types';
import {
  getCustomerVouchers,
  getShouldShowFailedGettingWelcomeVoucherModal,
  getShouldShowSuccessGettingWelcomeVoucherModal,
} from 'app-libs/redux_modules/entity_modules/selectors/voucher';
import {
  setShouldShowFailedGettingWelcomeVoucherModal,
  setShouldShowSuccessGettingWelcomeVoucherModal,
} from 'app-libs/redux_modules/entity_modules/voucher';

import FailureModalContent from './FailureModalContent';
import SuccessModalContent from './SuccessModalContent';

const WelcomeVoucherModal: React.FC = memo(() => {
  const dispatch = useDispatch();
  const shouldShowSuccessGettingWelcomeVoucherModal = useSelector(
    getShouldShowSuccessGettingWelcomeVoucherModal,
  );
  const shouldShowFailedGettingWelcomeVoucherModal = useSelector(
    getShouldShowFailedGettingWelcomeVoucherModal,
  );
  const customerVouchers: IVoucher[] = useSelector(getCustomerVouchers);

  const actionCloseModal = useCallback(() => {
    dispatch(setShouldShowFailedGettingWelcomeVoucherModal(false));
    dispatch(setShouldShowSuccessGettingWelcomeVoucherModal(false));
  }, [dispatch]);

  const welcomeVoucher = useMemo(
    () =>
      customerVouchers.find(
        (voucher) => voucher.isClaimed && voucher.isNewCustomerVoucher,
      ),
    [customerVouchers],
  );

  if (
    !welcomeVoucher ||
    (!shouldShowSuccessGettingWelcomeVoucherModal &&
      !shouldShowFailedGettingWelcomeVoucherModal)
  )
    return null;

  return (
    <>
      <ModalEnhanced
        isVisible={
          shouldShowSuccessGettingWelcomeVoucherModal ||
          shouldShowFailedGettingWelcomeVoucherModal
        }
        onBackdropPress={actionCloseModal}
        style={s.modalContainer}
      >
        {shouldShowSuccessGettingWelcomeVoucherModal && (
          <SuccessModalContent
            actionCloseModal={actionCloseModal}
            voucher={welcomeVoucher}
          />
        )}
        {!shouldShowSuccessGettingWelcomeVoucherModal &&
          shouldShowFailedGettingWelcomeVoucherModal && (
            <FailureModalContent actionCloseModal={actionCloseModal} />
          )}
      </ModalEnhanced>
    </>
  );
});

const s = StyleSheet.create({
  modalContainer: {
    margin: 0,
    justifyContent: 'flex-end',
  },
});

export default WelcomeVoucherModal;
