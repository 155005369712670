import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Text, TouchableWithoutFeedback, View } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-v2';

import ProfileAvatar from 'app-libs/components/Profile/Avatar';
import { makeParametricUrl } from 'app-libs/etc';
import * as authSelector from 'app-libs/redux_modules/selectors/auth';

import {
  K_ROUTE_DESIGNER_EDIT_PROFILE_V2,
  K_ROUTE_EDIT_PROFILE,
  K_ROUTE_LOGIN,
  K_ROUTE_SHIPPING_EVENT_LIST,
  K_ROUTE_WISHLIST,
} from 'constants/routeConstants';

import styles from './styles';

const K_AVATAR_SIZE = 48;

@connect((state) => ({
  user: authSelector.getAuthUser(state),
  isLoggedIn: authSelector.isLoggedIn(state),
  isAuthLoaded: authSelector.isAuthLoaded(state),
  isCurrentUserDesigner: authSelector.isCurrentUserDesigner(state),
}))
export default class DrawerMenuUserProfile extends React.Component {
  static propTypes = {
    handleDrawerMenuItemPress: PropTypes.func.isRequired,

    user: PropTypes.shape().isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isAuthLoaded: PropTypes.bool.isRequired,
    isCurrentUserDesigner: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { isLoggedIn, isAuthLoaded, user } = this.props;

    return (
      isLoggedIn !== nextProps.isLoggedIn ||
      isAuthLoaded !== nextProps.isAuthLoaded ||
      !isEqual(user, nextProps.user)
    );
  }

  getChangeProfileUrl() {
    const { isCurrentUserDesigner, user } = this.props;
    if (isCurrentUserDesigner) {
      return makeParametricUrl(K_ROUTE_DESIGNER_EDIT_PROFILE_V2, {
        userUuid: user.uuid,
      });
    }
    return K_ROUTE_EDIT_PROFILE;
  }

  actionOpenUrl = (linkUrl, isUsingAnchor = false) => {
    const { handleDrawerMenuItemPress } = this.props;

    handleDrawerMenuItemPress({
      linkUrl,
      routeOptions: { isUsingAnchor },
    });
  };

  renderLoadingSkeleton = () => (
    <React.Fragment>
      <View style={[styles.placeholder, styles.avatar]} />
      <View style={[cStyles.flex1, cStyles.mll]}>
        <View style={[styles.placeholder, { width: '60%', height: 28 }]} />
        <View
          style={[
            cStyles.mts,
            styles.placeholder,
            { width: '80%', height: 16 },
          ]}
        />
      </View>
    </React.Fragment>
  );

  renderProfile = () => {
    const { user } = this.props;
    const avatarProps = { width: K_AVATAR_SIZE, height: K_AVATAR_SIZE };
    const changeProfileUrl = this.getChangeProfileUrl();
    return (
      <React.Fragment>
        <TouchableWithoutFeedback
          onPress={() => this.actionOpenUrl(changeProfileUrl)}
        >
          <View>
            <ProfileAvatar userUuid={user.uuid} {...avatarProps} />
          </View>
        </TouchableWithoutFeedback>
        <View style={[cStyles.flex1, cStyles.mll]}>
          <Text data-cy="user-name-field" theme="h3">
            {user.firstName}
          </Text>
          <View style={cStyles.flexDirRow}>
            <Text
              theme="body4-tosca"
              onPress={() => this.actionOpenUrl(changeProfileUrl)}
            >
              Profil
            </Text>
            <Text theme="body4-black30" style={cStyles.phm}>
              |
            </Text>
            <Text
              theme="body4-tosca"
              onPress={() =>
                this.actionOpenUrl(K_ROUTE_SHIPPING_EVENT_LIST, true)
              }
            >
              Pesanan
            </Text>
            <Text theme="body4-black30" style={cStyles.phm}>
              |
            </Text>
            <Text
              theme="body4-tosca"
              onPress={() => this.actionOpenUrl(K_ROUTE_WISHLIST, true)}
            >
              Favorit
            </Text>
          </View>
        </View>
      </React.Fragment>
    );
  };

  renderLoginRegisterButton = () => (
    <ButtonWithText
      title="Masuk atau Daftar"
      sizeType="medium"
      style={cStyles.flex1}
      onPress={() => this.actionOpenUrl(K_ROUTE_LOGIN)}
    />
  );

  renderContent() {
    const { isAuthLoaded, isLoggedIn } = this.props;
    if (!isAuthLoaded) return this.renderLoadingSkeleton();
    if (isLoggedIn) return this.renderProfile();
    return this.renderLoginRegisterButton();
  }

  render() {
    return <View style={styles.container}>{this.renderContent()}</View>;
  }
}
