export const K_PORTFOLIO_PROPERTY_TYPE_HOME = 'rumah';
export const K_PORTFOLIO_PROPERTY_TYPE_APARTMENT = 'apartemen';
export const K_PORTFOLIO_PROPERTY_TYPE_LIST = [
  K_PORTFOLIO_PROPERTY_TYPE_HOME,
  K_PORTFOLIO_PROPERTY_TYPE_APARTMENT,
];

export const K_PORTFOLIO_ROOM_TYPE_KITCHEN_SET = 'kitchen-set';
export const K_PORTFOLIO_ROOM_TYPE_WALK_IN_CLOSET = 'walk-in-closet';
export const K_PORTFOLIO_ROOM_TYPE_LIST = [
  K_PORTFOLIO_ROOM_TYPE_KITCHEN_SET,
  K_PORTFOLIO_ROOM_TYPE_WALK_IN_CLOSET,
];

export const K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY = 'modern-kontemporer';
export const K_PORTFOLIO_STYLE_MODERN_CLASSIC = 'modern-klasik';
export const K_PORTFOLIO_STYLE_MODERN_TROPIC = 'modern-tropis';
export const K_PORTFOLIO_STYLE_JAPANDI_NATURAL = 'japandi-natural';
export const K_PORTFOLIO_STYLE_LIST = [
  K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY,
  K_PORTFOLIO_STYLE_MODERN_CLASSIC,
  // K_PORTFOLIO_STYLE_MODERN_TROPIC,
  K_PORTFOLIO_STYLE_JAPANDI_NATURAL,
];

export const K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_NAME = 'Modern Kontemporer';
export const K_PORTFOLIO_STYLE_MODERN_CLASSIC_NAME = 'Modern Klasik';
export const K_PORTFOLIO_STYLE_MODERN_TROPIC_NAME = 'Modern Tropis';
export const K_PORTFOLIO_STYLE_JAPANDI_NATURAL_NAME = 'Japandi Natural';

// @note(Ferro): Pathname to name conversion has to be separated by area levels to prevent cases like Jakarta Selatan -> DKI Jakarta Selatan
export const K_PORTFOLIO_AREA_PROVINCE_PATHNAME_TO_NAME_MAPPING = {
  Jakarta: 'DKI Jakarta',
};
export const K_PORTFOLIO_AREA_CITY_PATHNAME_TO_NAME_MAPPING = {
  Kabupaten: 'Kab.',
};
export const K_PORTFOLIO_AREA_DISTRICT_PATHNAME_TO_NAME_MAPPING = {
  'Kosambi Salembaran Jati': 'Kosambi /Salembaran Jati',
};
// @note(ferro): this is the inverted version.
export const K_PORTFOLIO_AREA_NAME_TO_PATHNAME_MAPPING = Object.entries({
  ...K_PORTFOLIO_AREA_PROVINCE_PATHNAME_TO_NAME_MAPPING,
  ...K_PORTFOLIO_AREA_CITY_PATHNAME_TO_NAME_MAPPING,
  ...K_PORTFOLIO_AREA_DISTRICT_PATHNAME_TO_NAME_MAPPING,
}).reduce((acc, [key, val]) => ({ ...acc, [val]: key }), {});
