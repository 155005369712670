import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';

import DrawerMenuItem from '../Item';
import { useFooterItems } from './utils';

interface FooterProps {
  handleDrawerMenuItemPress: (item: TYPEFIXME) => void;
}

const Footer: React.FC<FooterProps> = memo(({ handleDrawerMenuItemPress }) => {
  const footerItems = useFooterItems();

  return (
    <View style={s.container}>
      {footerItems.map((item) => (
        <DrawerMenuItem
          {...item} // intended, too much unrelated works to be done in order to not using spread (refactored from old component)
          key={item.label}
          shouldHideRightIcon
          containerStyle={s.footerItemContainer}
          handleDrawerMenuItemPress={() => handleDrawerMenuItemPress(item)}
        />
      ))}
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.ptm,
    ...cStyles.pbxxl,
  },
  footerItemContainer: {
    ...cStyles.pvm,
    backgroundColor: Colors.C_GREY_100,
  },
});

export default Footer;
