import React, { memo, useState } from 'react';

import MainDrawerMenu from 'app-libs/components/DrawerMenu';

import InteriorDrawerMenu from './InteriorDrawerMenu';

import { K_MAIN_DRAWER_MENU, K_INTERIOR_DRAWER_MENU } from './constants';
import { DrawerRef } from './types';

export interface DrawerProps {
  drawerRef: DrawerRef;
}

const Drawer: React.FC<DrawerProps> = memo(({ drawerRef }) => {
  const [activeMenu, setActiveMenu] = useState(K_INTERIOR_DRAWER_MENU);

  switch (activeMenu) {
    case K_MAIN_DRAWER_MENU:
      return <MainDrawerMenu drawerRef={drawerRef} />;
    case K_INTERIOR_DRAWER_MENU:
      return (
        <InteriorDrawerMenu
          drawerRef={drawerRef}
          setActiveMenu={setActiveMenu}
        />
      );
    default:
      return null;
  }
});

export default Drawer;
