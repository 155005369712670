import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { View } from '@dkrm/ui-kit-basic';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import config from 'config';

import styles from '@dkrm/general-libs/theme/styles';

const anonymousImageUrl = `${config.API_URL_MEDIA_CDN}/apps/image/anonymous.png?auto=webp`;

const K_ADDITIONAL_QUERY = { fit: 'facearea', facePad: '3' };

const Avatar = memo(({ imageUrl, width, height, accessibilityLabel }) => (
  <View>
    <SmartImage
      accessibilityLabel={accessibilityLabel}
      source={imageUrl}
      width={width}
      height={height}
      additionalQuery={K_ADDITIONAL_QUERY}
      style={{
        borderRadius: width / 2,
        borderWidth: 1,
        ...styles.bcm,
      }}
    />
  </View>
));

Avatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  accessibilityLabel: PropTypes.string,

  imageUrl: PropTypes.string,
};

Avatar.defaultProps = {
  imageUrl: anonymousImageUrl,
  width: 60,
  height: 60,
  accessibilityLabel: null,
};

export default Avatar;
