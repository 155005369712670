import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  container: {
    ...cStyles.phxxl,
    ...cStyles.pvxl,
    ...cStyles.flexDirRow,
    ...cStyles.bgw,
    alignItems: 'center',
  },
  icon: {
    ...cStyles.mrl,
  },
  title: {
    ...cStyles.bgw,
    ...cStyles.phxxl,
    ...cStyles.ptxl,
    ...tStyles.body4,
    color: colors.C_BLACK_60,
  },
  label: {
    ...cStyles.flex1,
    ...tStyles.body2,
    color: colors.C_BLACK_85,
  },
});
