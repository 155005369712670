import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Build, ChevronLeft } from '@dkrm/icons';
import {
  HorizontalDivider,
  ScrollView,
  StyleSheet,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { getUrlWithQuery } from 'app-libs/etc/routeHelper';
import { useLocation, useParams } from 'app-libs/hook_modules/router';
import {
  getCartLinesValuePublic,
  getSummaryProjectByKey,
  isCommercialProject,
} from 'app-libs/redux_modules/entity_modules/selectors/project';
import {
  isLoggedIn as _isLoggedIn,
  getAuthUser,
} from 'app-libs/redux_modules/selectors/auth';
import {
  trackClickFreeConsultation,
  trackClickedCoverageArea,
  trackClickedEntrypointSOMA,
  trackClickedHowItWorks,
  trackClickedPortfolioList,
  trackOpenedProductOfferingDropdown,
} from 'app-libs/trackers';

import { K_PRODUCT_OFFERING_NAVIGATION_DATA } from 'components/project/constants';

import {
  K_ROUTE_BUSINESS_REQUEST_FORM,
  K_ROUTE_COVERAGE_AREA,
  K_ROUTE_DESIGN_BUILD,
  K_ROUTE_DESIGN_BUILD_HOW_IT_WORKS,
  K_ROUTE_DESIGN_BUILD_PROJECT_SELECT,
  K_ROUTE_DESIGN_REQUEST_FORM,
  K_ROUTE_LOGIN,
  K_ROUTE_PORTFOLIO_LIST,
  K_ROUTE_SOMA,
} from 'constants/routeConstants';

import { K_MAIN_DRAWER_MENU } from '../constants';
import { DrawerRef } from '../types';
import ButtonWithText from './ButtonWithText';
import NavigationItem from './NavigationItem';
import Text from './Text';
import colors from './colors';

const K_USING_ANCHOR_LINK_PROPS = { isUsingAnchor: true };

export interface InteriorDrawerMenuProps {
  drawerRef: DrawerRef;
  setActiveMenu: (activeMenu: string) => void;
}

const K_NAVIGATION_DATA = [
  {
    label: 'Produk',
    children: K_PRODUCT_OFFERING_NAVIGATION_DATA,
    trackerFunction: trackOpenedProductOfferingDropdown,
  },
  {
    label: 'How it Works',
    to: K_ROUTE_DESIGN_BUILD_HOW_IT_WORKS,
    isUsingAnchor: true,
    trackerFunction: trackClickedHowItWorks,
  },
  {
    label: 'Portofolio',
    to: K_ROUTE_PORTFOLIO_LIST,
    trackerFunction: trackClickedPortfolioList,
  },
  {
    label: 'Area Jangkauan',
    to: K_ROUTE_COVERAGE_AREA,
    isUsingAnchor: true,
    trackerFunction: trackClickedCoverageArea,
  },
  {
    label: 'Thudio untuk Desainer',
    to: K_ROUTE_SOMA,
    isUsingAnchor: true,
    trackerFunction: trackClickedEntrypointSOMA,
  },
];

const InteriorDrawerMenu: React.FC<InteriorDrawerMenuProps> = memo(
  ({ drawerRef, setActiveMenu }) => {
    const { projectKey } = useParams<{ projectKey: string }>();
    const location = useLocation();

    const isLoggedIn = useSelector(_isLoggedIn);
    const user = useSelector(getAuthUser);
    const project = useSelector((state) =>
      getSummaryProjectByKey(state, projectKey),
    );

    const backToMainMenu = useCallback(
      () => setActiveMenu(K_MAIN_DRAWER_MENU),
      [setActiveMenu],
    );

    const actionCloseDrawer = useCallback(
      () => drawerRef.current?.closeDrawer(),
      [drawerRef],
    );

    const requestUrl = useMemo(() => {
      const queries: { ref?: string; refValue?: string } = {};

      const cartLinesValuePublic = getCartLinesValuePublic(project);

      if (project) {
        queries.ref = project.projectKey;
        queries.refValue = cartLinesValuePublic;
      }

      return getUrlWithQuery(
        isCommercialProject(project)
          ? K_ROUTE_BUSINESS_REQUEST_FORM
          : K_ROUTE_DESIGN_REQUEST_FORM,
        queries,
      );
    }, [project]);

    const actionTrackClickedCTA = useCallback(
      () =>
        trackClickFreeConsultation(
          isCommercialProject(project)
            ? K_ROUTE_BUSINESS_REQUEST_FORM
            : K_ROUTE_DESIGN_REQUEST_FORM,
          location.pathname,
          project,
          user,
          { via: 'drawer-dnb' },
        ),
      [location.pathname, project, user],
    );

    return (
      <View style={s.container}>
        <ButtonWrapper onPress={backToMainMenu} style={s.backToMainMenuButton}>
          {() => (
            <>
              <ChevronLeft size={24} theme="black" />
              <Text theme="h5" style={s.titleText}>
                Back to Main Menu
              </Text>
            </>
          )}
        </ButtonWrapper>
        <HorizontalDivider borderWidth={4} color={colors.C_BLACK_20} />
        <ButtonWrapper
          to={K_ROUTE_DESIGN_BUILD}
          linkProps={K_USING_ANCHOR_LINK_PROPS}
          style={s.titleContainer}
        >
          {() => (
            <>
              <Build size={24} theme="black" />
              <Text theme="h4" style={s.titleText}>
                Interior
              </Text>
            </>
          )}
        </ButtonWrapper>
        <ScrollView>
          {K_NAVIGATION_DATA.map((navigation, index) => (
            <NavigationItem
              key={index}
              actionCloseDrawer={actionCloseDrawer}
              {...navigation}
            />
          ))}
        </ScrollView>
        <View style={s.ctaContainer}>
          <ButtonWithText
            label="Tanya Harga"
            labelTheme="l-white"
            color={colors.C_PRIMARY_RED}
            style={s.askPriceButton}
            to={requestUrl}
            onPress={actionTrackClickedCTA}
            linkProps={{ isUsingAnchor: true }}
          />
          {isLoggedIn ? (
            <ButtonWithText
              label="Proyek Saya"
              labelTheme="l"
              style={s.secondaryButton}
              to={K_ROUTE_DESIGN_BUILD_PROJECT_SELECT}
              linkProps={K_USING_ANCHOR_LINK_PROPS}
            />
          ) : (
            <ButtonWithText
              label="Masuk"
              labelTheme="l"
              style={s.secondaryButton}
              to={K_ROUTE_LOGIN}
            />
          )}
        </View>
      </View>
    );
  },
);

export default InteriorDrawerMenu;

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.bgw,
  },
  backToMainMenuButton: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.paxl,
  },
  titleText: cStyles.mlm,
  titleContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.pvxl,
    ...cStyles.mhxl,
    ...cStyles.bwbm,
    borderColor: colors.C_BLACK_20,
  },
  ctaContainer: {
    ...cStyles.paxxl,
    ...cStyles.bwtm,
    borderColor: colors.C_BLACK_20,
  },
  askPriceButton: cStyles.width100,
  secondaryButton: {
    ...cStyles.mtxl,
    ...cStyles.widh100,
    ...cStyles.bwam,
    borderColor: colors.C_BLACK_100,
  },
});
