import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Avatar from 'app-libs/components/Avatar';
import { getUserByUserUuid } from 'app-libs/redux_modules/profile_modules/selectors';

@connect((state, props) => ({
  user: getUserByUserUuid(state, props.userUuid),
}))
export default class ProfileAvatar extends Component {
  static propTypes = {
    userUuid: PropTypes.number.isRequired,
    user: PropTypes.shape().isRequired,
  };

  render() {
    const { user, ...rest } = this.props;
    const { photoUrl } = user;
    return <Avatar {...rest} imageUrl={photoUrl} />;
  }
}
