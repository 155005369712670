import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import {
  expiredDateInUTC,
  getCookieByName,
  setCookie,
} from 'app-libs/etc/cookieHelper';

import withRouter from '@dkrm/general-libs/Utils/withRouter';

import AppBannerContext from './AppBannerContext';

const K_INSTALL_APP_BANNER_DISMISSED_PERIOD_IN_DAYS = 7;

export const AppBannerProvider = (props) => {
  const { queries, ...rest } = props;
  const [isAppBannerShown, setIsAppBannerShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!isWebView(queries) && !getCookieByName('top-banner-dismissed')) {
        setIsAppBannerShown(true);
      }
    }, 5000);
  });

  const dismissAppBanner = useCallback(() => {
    setIsAppBannerShown(false);
    setCookie(
      'top-banner-dismissed',
      true,
      expiredDateInUTC(K_INSTALL_APP_BANNER_DISMISSED_PERIOD_IN_DAYS),
    );
  }, []);

  const showAppBanner = useCallback(() => setIsAppBannerShown(true), []);

  return (
    <AppBannerContext.Provider
      {...rest}
      value={{ isAppBannerShown, dismissAppBanner, showAppBanner }}
    />
  );
};

AppBannerProvider.propTypes = {
  queries: PropTypes.shape().isRequired,
};

export default withRouter(AppBannerProvider);
