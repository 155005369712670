import { PropTypes } from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { PaperMoney } from '@dkrm/icons';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

const FamilyReferralBanner = ({
  label,
  containerStyle,
  handleDrawerMenuItemPress,
}) => {
  return (
    <ButtonWrapper
      key={label}
      onPress={handleDrawerMenuItemPress}
      hoverableViewStyle={s.buttonContainer}
    >
      {() => (
        <View style={[containerStyle, s.bannerContainer]}>
          <View style={s.iconContainer}>
            <PaperMoney theme="black" />
          </View>
          <View style={s.textContainer}>
            <Text theme="h5-tosca" style={s.title}>
              Dapatkan bonus hingga 20jt
            </Text>
            <Text theme="body4-black60">dengan program referral properti</Text>
          </View>
        </View>
      )}
    </ButtonWrapper>
  );
};

const s = StyleSheet.create({
  buttonContainer: {
    ...cStyles.mhxxl,
    ...cStyles.mtl,
    ...cStyles.mbm,
  },
  bannerContainer: {
    ...cStyles.flexMiddleLeft,
    ...cStyles.flexDirRow,
    ...cStyles.pal,
    ...cStyles.bgw,
    ...cStyles.bwam,
    ...cStyles.brs,
    borderColor: colors.C_PRIMARY_TOSCA,
  },
  iconContainer: {
    ...cStyles.mlxs,
    ...cStyles.flexMiddle,
    paddingRight: 1,
    height: 24,
    width: 24,
    borderRadius: 16,
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
  },
  textContainer: {
    ...cStyles.plm,
    ...cStyles.mrxs,
    ...cStyles.flex1,
  },
  title: {
    fontSize: 14,
  },
});

FamilyReferralBanner.propTypes = {
  label: PropTypes.string.isRequired,
  containerStyle: stylePropTypes,
  handleDrawerMenuItemPress: PropTypes.func.isRequired,
};

FamilyReferralBanner.defaultProps = {
  containerStyle: null,
};

export default FamilyReferralBanner;
