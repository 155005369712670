import React from 'react';

import InteriorGTM from 'components/project/InteriorGTM';

import DrawerContainer from '../DrawerContainer';
import Drawer from './Drawer';

const DnbDrawerContainer: React.FC = ({ children }) => {
  return (
    <>
      {process.env.NODE_ENV !== 'development' && <InteriorGTM />}
      <DrawerContainer DrawerComponent={Drawer}>{children}</DrawerContainer>
    </>
  );
};

export default DnbDrawerContainer;
