import { ApartmentOutline, Build, Home, SofaOutline } from '@dkrm/icons';

import { getPathFromUrl } from 'app-libs/etc/routeHelper';
import wv from 'app-libs/libs/webView';

import FamilyReferralBanner from './components/membership/FamilyReferralBanner';

import {
  K_PRIVACY_POLICY,
  K_RETAIL_WARRANTY,
  K_ROUTE_ABOUT_US,
  K_ROUTE_DESIGN_BUILD,
  K_ROUTE_DESIGN_BUILD_PROJECT_SELECT,
  K_ROUTE_HELP,
  K_ROUTE_HOUSE,
  K_ROUTE_HOUSE_DAILY_TASKS,
  K_ROUTE_MEMBERSHIP_DASHBOARD_FAMILY,
  K_ROUTE_MERCHANT_V3,
  K_ROUTE_PRODUCT_HOMEPAGE,
} from 'constants/routeConstants';

export const K_USER_GROUP_BUSINESS_MANAGER = 'Business Manager';
export const K_USER_GROUP_CUSTOMER_PROJECT_MANAGER = 'Customer Project Manager';
export const K_USER_GROUP_DESIGNER = 'Designer';
export const K_USER_GROUP_DRAFTER = 'Drafter';
export const K_USER_GROUP_INTERIOR_DESIGNER_MANAGER =
  'Interior Designer Manager';
export const K_USER_GROUP_PHOTOGRAPHER = 'Photographer';
export const K_USER_GROUP_PRODUCTION_CONTROL = 'Production Control';
export const K_USER_GROUP_PROJECT_MANAGER = 'Project Manager';
export const K_USER_GROUP_QUANTITY_SURVEYOR = 'Quantity Surveyor';
export const K_USER_GROUP_SALES_PERSON = 'Sales Person';
export const K_USER_GROUP_SUPPLY_MANAGER = 'Supply Manager';
export const K_USER_GROUP_TECHNICAL_VISIT_SPECIALIST =
  'Technical Visit Specialist';
export const K_USER_GROUP_WORKSHOP = 'Workshop';
export const K_USER_GROUP_ACCOUNT_MANAGER = 'Account Manager';
export const K_USER_GROUP_AGENT_PARTNER = 'Agent Partner';
export const K_ALLOWED_USER_GROUPS_SWITCH_DASHBOARD = [
  K_USER_GROUP_BUSINESS_MANAGER,
  K_USER_GROUP_CUSTOMER_PROJECT_MANAGER,
  K_USER_GROUP_DESIGNER,
  K_USER_GROUP_DRAFTER,
  K_USER_GROUP_PHOTOGRAPHER,
  K_USER_GROUP_PRODUCTION_CONTROL,
  K_USER_GROUP_PROJECT_MANAGER,
  K_USER_GROUP_QUANTITY_SURVEYOR,
  K_USER_GROUP_SALES_PERSON,
  K_USER_GROUP_SUPPLY_MANAGER,
  K_USER_GROUP_WORKSHOP,
  K_USER_GROUP_ACCOUNT_MANAGER,
  K_USER_GROUP_AGENT_PARTNER,
];

export const K_DRAWER_TYPE_MAIN_MENU = 'mainMenu';
export const K_DRAWER_TYPE_DASHBOARD_LIST = 'dashboardList';

export const K_MAIN_DRAWER_CONTENT_ITEMS = [
  {
    Icon: Home,
    label: 'Beranda',
    linkUrl: '/',
  },
  {
    Icon: SofaOutline,
    label: 'Furnitur & Aksesoris',
    linkUrl: K_ROUTE_PRODUCT_HOMEPAGE,
  },
  {
    Icon: Build,
    label: 'Desain Interior',
    linkUrl: K_ROUTE_DESIGN_BUILD,
    routeOptions: { isUsingAnchor: true },
  },
  {
    Icon: ApartmentOutline,
    label: 'Properti',
    linkUrl: K_ROUTE_HOUSE,
    routeOptions: { isUsingAnchor: true },
  },
];

export const K_BASE_DRAWER_FOOTER_ITEMS = [
  {
    label: 'Proyek Interior Saya',
    linkUrl: getPathFromUrl(K_ROUTE_DESIGN_BUILD_PROJECT_SELECT),
    routeOptions: { isUsingAnchor: true },
  },
  {
    label: 'Bantuan',
    linkUrl: getPathFromUrl(K_ROUTE_HELP),
    routeOptions: { isUsingAnchor: true },
  },
  {
    label: 'Garansi Retail',
    linkUrl: getPathFromUrl(K_RETAIL_WARRANTY),
    routeOptions: { isUsingAnchor: true },
  },
  {
    label: 'Tentang Kami',
    linkUrl: getPathFromUrl(K_ROUTE_ABOUT_US),
    routeOptions: { isUsingAnchor: true },
  },
  {
    label: 'Kebijakan Privasi',
    linkUrl: getPathFromUrl(K_PRIVACY_POLICY),
    routeOptions: { isUsingAnchor: true },
  },
];

export const K_MEMBERSHIP_DRAWER_FOOTER_ITEMS = [
  {
    label: 'Referral Family',
    Component: FamilyReferralBanner,
    linkUrl: K_ROUTE_MEMBERSHIP_DASHBOARD_FAMILY,
    routeOptions: { isUsingAnchor: true },
  },
];

export const K_ROLE_WORKSHOP_DRAWER_FOOTER_ITEMS = [
  {
    label: 'Dashboard Workshop',
    linkUrl: 'https://www.dekoruma.com/merchant-v3',
    routeOptions: { isUsingAnchor: true },
  },
];

const K_DASHBOARD_DEKORUMA_HOMEPAGE = 'Dekoruma Homepage';
const K_DASHBOARD_PROJECT_CENTRAL = 'Project Central';
const K_DASHBOARD_MERCHANT_CENTRAL = 'Merchant Central';
const K_DASHBOARD_PROPERTY_CENTRAL = 'Property Central';

export const K_DASHBOARD_LIST_DRAWER_CONTENT_ITEMS = [
  {
    label: K_DASHBOARD_DEKORUMA_HOMEPAGE,
    linkUrl: '/',
    onPress: () =>
      wv.setDefaultDashboard({ name: K_DASHBOARD_DEKORUMA_HOMEPAGE, url: '/' }),
  },
  {
    label: K_DASHBOARD_PROJECT_CENTRAL,
    linkUrl: K_ROUTE_DESIGN_BUILD_PROJECT_SELECT,
    routeOptions: { isUsingAnchor: true },
    onPress: () =>
      wv.setDefaultDashboard({
        name: K_DASHBOARD_PROJECT_CENTRAL,
        url: K_ROUTE_DESIGN_BUILD_PROJECT_SELECT,
      }),
    allowedUserGroups: [
      K_USER_GROUP_BUSINESS_MANAGER,
      K_USER_GROUP_CUSTOMER_PROJECT_MANAGER,
      K_USER_GROUP_DESIGNER,
      K_USER_GROUP_DRAFTER,
      K_USER_GROUP_INTERIOR_DESIGNER_MANAGER,
      K_USER_GROUP_PHOTOGRAPHER,
      K_USER_GROUP_PRODUCTION_CONTROL,
      K_USER_GROUP_PROJECT_MANAGER,
      K_USER_GROUP_QUANTITY_SURVEYOR,
      K_USER_GROUP_SALES_PERSON,
      K_USER_GROUP_SUPPLY_MANAGER,
      K_USER_GROUP_TECHNICAL_VISIT_SPECIALIST,
    ],
  },
  {
    label: K_DASHBOARD_MERCHANT_CENTRAL,
    linkUrl: K_ROUTE_MERCHANT_V3,
    routeOptions: { isUsingAnchor: true },
    onPress: () =>
      wv.setDefaultDashboard({
        name: K_DASHBOARD_MERCHANT_CENTRAL,
        url: K_ROUTE_MERCHANT_V3,
      }),
    allowedUserGroups: [K_USER_GROUP_WORKSHOP],
  },
  {
    label: K_DASHBOARD_PROPERTY_CENTRAL,
    linkUrl: K_ROUTE_HOUSE_DAILY_TASKS,
    routeOptions: { isUsingAnchor: true },
    onPress: () =>
      wv.setDefaultDashboard({
        name: K_DASHBOARD_PROPERTY_CENTRAL,
        url: K_ROUTE_HOUSE_DAILY_TASKS,
      }),
    allowedUserGroups: [K_USER_GROUP_ACCOUNT_MANAGER],
  },
];
