import React from 'react';

import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

const Separator = () => (
  <View style={cStyles.bgw}>
    <View style={s.separator} />
  </View>
);

const s = StyleSheet.create({
  separator: {
    ...cStyles.mhxxl,
    ...cStyles.bwam,
    borderColor: colors.C_BLACK_5,
  },
});

export default Separator;
