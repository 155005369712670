import get from 'lodash/get';

const K_MEMBERSHIP_TYPE_PRO = 'Pro';
const K_MEMBERSHIP_TYPE_FAMILY = 'Family';

export const isValidatingReferralCode = (state) =>
  state.membership.referralCode.asyncState.loading;

export const isMemberLoaded = (state) => {
  const {
    membership: {
      member: { asyncState },
    },
  } = state;
  if (asyncState.loaded === false) {
    return asyncState.loadingError !== undefined;
  }
  return asyncState.loaded;
};
export const getMemberUser = (state) => state.membership.member.result;

export const isMemberReferralsLoaded = (state) => {
  const {
    membership: {
      referrals: { asyncState },
    },
  } = state;
  if (asyncState.loaded === false) {
    return asyncState.loadingError !== undefined;
  }
  return asyncState.loaded;
};

export const getMemberReferrals = (state) => state.membership.referrals.result;

export const getMembershipType = (state) => {
  const member = getMemberUser(state);
  const membershipType = get(member, 'membershipInfo.type');

  return membershipType;
};

export const isMemberPro = (state) => {
  const membershipType = getMembershipType(state);
  return membershipType === K_MEMBERSHIP_TYPE_PRO;
};

export const isMemberFamily = (state) => {
  const membershipType = getMembershipType(state);
  return membershipType === K_MEMBERSHIP_TYPE_FAMILY;
};
