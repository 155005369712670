import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Close } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWithText, ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { Voucher as IVoucher } from 'app-libs/components/campaign/types';

import Voucher from 'components/account/components/ClaimVoucherSection/Voucher';

import { K_ROUTE_PROMOTION } from 'constants/routeConstants';

interface SuccessModalContentProps {
  voucher: IVoucher;
  actionCloseModal: () => void;
}

const SuccessModalContent: React.FC<SuccessModalContentProps> = memo(
  ({ voucher, actionCloseModal }) => {
    return (
      <View style={s.modalContainer}>
        <ButtonWrapper onPress={actionCloseModal}>
          {() => <Close size={24} theme="black" style={s.closeIcon} />}
        </ButtonWrapper>
        <View style={s.content}>
          <Text theme="body3" style={s.text}>
            Selamat! Kamu mendapatkan:
          </Text>
          <Voucher voucher={voucher} />
          <ButtonWithText
            title="Cek Voucher"
            buttonStyle={s.button}
            themeType="primary"
            onPress={actionCloseModal}
            to={K_ROUTE_PROMOTION}
          />
        </View>
      </View>
    );
  },
);

const s = StyleSheet.create({
  modalContainer: {
    ...cStyles.bgw,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  closeIcon: {
    ...cStyles.mvl,
    marginHorizontal: 20,
  },
  text: {
    ...cStyles.tac,
    ...cStyles.mbxl,
  },
  content: {
    ...cStyles.paxl,
    ...cStyles.pbxxxl,
  },
  button: {
    ...cStyles.brm,
    ...cStyles.mtxxl,
    backgroundColor: Colors.C_TEAL_500,
  },
});

export default SuccessModalContent;
