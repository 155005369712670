import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const K_GTM_ID_INTERIOR = 'GTM-PFR9NT8';

const InteriorGTM = memo(() => {
  useEffect(() => {
    const scriptTxt = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${K_GTM_ID_INTERIOR}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
    const scriptElement = new DOMParser().parseFromString(scriptTxt, 'text/xml')
      .firstChild as Element;

    document.body.insertAdjacentElement('afterbegin', scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return (
    <Helmet>
      {/* extracted from the original script */}
      <script
        async
        src={`https://www.googletagmanager.com/gtm.js?id=${K_GTM_ID_INTERIOR}`}
      />
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});})(window,document,'script','dataLayer','${K_GTM_ID_INTERIOR}');`}</script>
    </Helmet>
  );
});

export default InteriorGTM;
