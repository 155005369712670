import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

const getUserDetailState = (state) => state.profile.userDetail;
const getDesignerProfileState = (state) =>
  state.profile.designerProfile.designerProfile;
const getDesignerProfileAsyncState = (state) =>
  state.profile.designerProfile.asyncState;

export const getUserByUserUuid = (state, userUuid) => {
  const userDetailState = getUserDetailState(state);
  const { byUserUuid } = userDetailState;
  return get(byUserUuid, userUuid, {});
};

export function isDesignerProfileLoaded(state) {
  return !isEmpty(getDesignerProfileState(state));
}

export const getDesignerProfile = (state) => {
  const designerProfile = getDesignerProfileState(state);
  const asyncState = getDesignerProfileAsyncState(state);
  return merge({}, designerProfile, asyncState);
};
