import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Text } from '@dkrm/ui-kit-basic';
import { ArrowBack } from '@dkrm/icons';
import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import cStyles from '@dkrm/general-libs/theme/styles';
import { getAuthUser } from 'app-libs/redux_modules/selectors/auth';

import DrawerMenuItem from '../Item';
import Separator from '../Separator';

import { filterAlowedMenuItems } from '../helper';

import {
  K_DRAWER_TYPE_MAIN_MENU,
  K_DASHBOARD_LIST_DRAWER_CONTENT_ITEMS,
} from '../constants';

export const DrawerMenuDashboardList = ({
  actionChangeDrawerMenuType,
  handleDrawerMenuItemPress,
}) => {
  const user = useSelector(getAuthUser);
  const backItem = {
    Icon: ArrowBack,
    label: 'Kembali',
    onPressWithoutCloseDrawer: () =>
      actionChangeDrawerMenuType(K_DRAWER_TYPE_MAIN_MENU),
    shouldHideRightIcon: true,
  };
  const drawerDashboardListItems = filterAlowedMenuItems(
    [backItem, ...K_DASHBOARD_LIST_DRAWER_CONTENT_ITEMS],
    user,
  );

  return (
    <>
      {drawerDashboardListItems.map((item, idx) => (
        <React.Fragment key={item.label}>
          {!!idx && <Separator />}
          <DrawerMenuItem
            {...item}
            handleDrawerMenuItemPress={() => handleDrawerMenuItemPress(item)}
          />
        </React.Fragment>
      ))}
      {isWebView() && (
        <Text theme="body5-black60" style={[cStyles.phxxl, cStyles.ptxl]}>
          Halaman terpilih akan menjadi halaman default ketika Anda membuka
          aplikasi Dekoruma
        </Text>
      )}
    </>
  );
};

DrawerMenuDashboardList.propTypes = {
  actionChangeDrawerMenuType: PropTypes.func.isRequired,
  handleDrawerMenuItemPress: PropTypes.func.isRequired,
};

export default DrawerMenuDashboardList;
