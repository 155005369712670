import { StyleSheet } from 'react-native';

import colors from '@dkrm/ui-kit-basic/colors';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    ...cStyles.paxxl,
    alignItems: 'center',
    height: 96,
    backgroundColor: colors.C_WHITE_100,
  },

  placeholder: {
    backgroundColor: '#EFEFEF',
    borderRadius: 2,
  },

  avatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },

  login: {
    ...cStyles.bwam,
    borderColor: colors.C_WHITE_100,
  },
});
