import has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  isIphone,
  isWebView,
} from '@dkrm/general-libs/Utils/userAgentDetector';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles from '@dkrm/general-libs/theme/styles';
import { SwapCalls } from '@dkrm/icons';
import {
  Colors,
  HorizontalDivider,
  ResponsiveImage,
  ScrollView,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import { isDnBPage } from 'app-libs/etc/routeHelper';
import { withActionOpenUrl } from 'app-libs/hook_modules/actionOpenUrl';
import {
  getAuthUser,
  getIsShopAssistant,
} from 'app-libs/redux_modules/selectors/auth';
import {
  trackClickedAppBanner,
  trackClickedDrawerMenuItem,
} from 'app-libs/trackers';

import AppVersion from 'components/AppVersion';

import { K_DEKORUMA_APP_ICON } from 'constants/assetConstants';
import {
  K_APPLE_STORE_LABEL,
  K_PLAY_STORE_LABEL,
} from 'constants/nativeAppConstants';
import {
  K_ROUTE_CART,
  K_ROUTE_SMART_BANNER_DRAWER_MENU,
  K_ROUTE_SMART_BANNER_DRAWER_MENU_DNB,
} from 'constants/routeConstants';
import { K_TRACKING_PLACEMENT_DRAWER_MENU } from 'constants/trackingConstants';

import DashboardList from './DashboardList';
import Footer from './Footer';
import Item from './Item';
import UserProfile from './UserProfile';
import {
  K_ALLOWED_USER_GROUPS_SWITCH_DASHBOARD,
  K_DRAWER_TYPE_DASHBOARD_LIST,
  K_DRAWER_TYPE_MAIN_MENU,
  K_MAIN_DRAWER_CONTENT_ITEMS,
} from './constants';
import { filterAlowedMenuItems } from './helper';
import styles from './styles';

const K_DEKORUMA_APP_ICON_SIZE = 30;

@withRouter
@connect((state) => ({
  user: getAuthUser(state),
  isShopAssistant: getIsShopAssistant(state),
}))
@withActionOpenUrl
export default class DrawerMenu extends React.PureComponent {
  static propTypes = {
    drawerRef: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    isShopAssistant: PropTypes.bool.isRequired,

    history: PropTypes.shape().isRequired,
    actionOpenUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: K_DRAWER_TYPE_MAIN_MENU,
    };
  }

  handleDrawerMenuItemPress = ({ linkUrl, routeOptions, onPress }) => {
    const { drawerRef, history, actionOpenUrl } = this.props;
    if (has(drawerRef, 'current.closeDrawer')) {
      drawerRef.current.closeDrawer();
    }
    trackClickedDrawerMenuItem(linkUrl);
    setTimeout(() => {
      if (onPress) onPress();
      if (linkUrl) actionOpenUrl(linkUrl, history, routeOptions);
    }, 750);
  };

  actionChangeDrawerMenuType = (type) => {
    this.setState({ type });
  };

  renderLogoAndText = () => {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    return (
      <View style={styles.textAndLogoContainer}>
        <ResponsiveImage
          source={{
            uri: K_DEKORUMA_APP_ICON,
            width: K_DEKORUMA_APP_ICON_SIZE,
          }}
          widthProportion={1}
          heightProportion={1}
          style={styles.logo}
          resizeMode="contain"
        />
        <View style={cStyles.mrm}>
          <Text theme="body4-white">
            {!isDnBPage(pathname) ? 'Belanja' : 'Interior'} lebih mudah di
          </Text>
          <Text theme="h6-white">Aplikasi Dekoruma</Text>
        </View>
      </View>
    );
  };

  renderDownloadApp = () => {
    if (isWebView()) return null;
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const endpoint = isIphone() ? K_APPLE_STORE_LABEL : K_PLAY_STORE_LABEL;
    return (
      <View style={styles.downloadAppContainer}>
        {this.renderLogoAndText()}
        <ButtonWithText
          to={
            isDnBPage(pathname)
              ? K_ROUTE_SMART_BANNER_DRAWER_MENU_DNB
              : K_ROUTE_SMART_BANNER_DRAWER_MENU
          }
          onPress={() =>
            trackClickedAppBanner(K_TRACKING_PLACEMENT_DRAWER_MENU, endpoint)
          }
          title="Download"
          theme="tosca"
          themeType="secondary"
          anchor
          small
          textStyle={styles.downloadText}
          buttonStyle={styles.downloadButtonContainer}
        />
      </View>
    );
  };

  renderUserProfile = () => (
    <UserProfile handleDrawerMenuItemPress={this.handleDrawerMenuItemPress} />
  );

  renderDrawerMenuBody = () => {
    const { user } = this.props;
    const dashboardItem = {
      Icon: SwapCalls,
      title: 'DASHBOARD',
      label: 'Ganti Dashboard',
      allowedUserGroups: K_ALLOWED_USER_GROUPS_SWITCH_DASHBOARD,
      onPressWithoutCloseDrawer: () =>
        this.actionChangeDrawerMenuType(K_DRAWER_TYPE_DASHBOARD_LIST),
    };
    const drawerMenuBodyItems = filterAlowedMenuItems(
      [...K_MAIN_DRAWER_CONTENT_ITEMS, dashboardItem],
      user,
    );

    return drawerMenuBodyItems.map((item, idx) => (
      <React.Fragment key={item.label}>
        {!!idx && this.renderSeparator()}
        <Item
          {...item}
          handleDrawerMenuItemPress={() => this.handleDrawerMenuItemPress(item)}
        />
      </React.Fragment>
    ));
  };

  renderSeparator = () => (
    <View style={cStyles.bgw}>
      <View style={styles.separator} />
    </View>
  );

  renderDrawerMenuFooter = () => (
    <Footer handleDrawerMenuItemPress={this.handleDrawerMenuItemPress} />
  );

  renderBarcodeScannerButton = () => {
    return (
      <ButtonWithText
        onPress={() =>
          this.handleDrawerMenuItemPress({
            linkUrl: K_ROUTE_CART,
            routeOptions: { isUsingAnchor: true },
          })
        }
        title="Scan Barcode dengan Scanner"
        theme="tosca"
        small
        textStyle={styles.downloadText}
        buttonStyle={styles.downloadButtonContainer}
        hoverableViewStyle={styles.scannerButtonContainer}
      />
    );
  };

  renderDrawerMainMenu = () => {
    const { isShopAssistant } = this.props;
    return (
      <View style={styles.drawerMenuContainer}>
        {/* Need view to maintain justify content space between */}
        <View>
          {this.renderDownloadApp()}
          {this.renderUserProfile()}
          {isShopAssistant && this.renderBarcodeScannerButton()}
          <HorizontalDivider color={Colors.C_BLACK_15} />
          {this.renderDrawerMenuBody()}
          {this.renderDrawerMenuFooter()}
        </View>
        <AppVersion />
      </View>
    );
  };

  renderDrawerDashboardList = () => (
    <DashboardList
      actionChangeDrawerMenuType={this.actionChangeDrawerMenuType}
      handleDrawerMenuItemPress={this.handleDrawerMenuItemPress}
    />
  );

  render() {
    const { type } = this.state;

    return (
      <ScrollView
        style={styles.container}
        contentContainerStyle={cStyles.flex1}
      >
        {type === K_DRAWER_TYPE_MAIN_MENU && this.renderDrawerMainMenu()}
        {type === K_DRAWER_TYPE_DASHBOARD_LIST &&
          this.renderDrawerDashboardList()}
      </ScrollView>
    );
  }
}
