import intersection from 'lodash/intersection';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const filterAlowedMenuItems = (items, user) =>
  items.filter(({ allowedUserGroups }) => {
    const isStaff = get(user, 'isStaff', false);
    const userGroups = get(user, 'groups', []);
    if (isEmpty(allowedUserGroups) || isStaff) return true;
    return !isEmpty(intersection(userGroups, allowedUserGroups));
  });
