import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { removeNotificationUserDevice } from 'app-libs/components/_abstractComponents/notification/utils';
import { logout } from 'app-libs/redux_modules/auth';
import { loadCustomerVouchers } from 'app-libs/redux_modules/entity_modules/voucher';
import {
  dontShowPromptOnError,
  showSnackBar,
} from 'app-libs/redux_modules/flow_modules/notification';
import { trackLogoutUser } from 'app-libs/trackers';

import {
  getLoadUnseenNotificationsCountQueryKey,
  getLoadUserNotificationsQueryKey,
} from 'entities/notification/api';

export const useLogout = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const actionLogout = useCallback(async () => {
    trackLogoutUser();
    const res = await dispatch(dontShowPromptOnError(logout()));
    if (res && res.result) {
      queryClient.removeQueries(getLoadUserNotificationsQueryKey());
      queryClient.removeQueries(getLoadUnseenNotificationsCountQueryKey());
    }
    removeNotificationUserDevice();
    dispatch(
      showSnackBar({
        message: 'Berhasil keluar',
      }),
    );
    dispatch(loadCustomerVouchers());
  }, [dispatch, queryClient]);

  return { actionLogout };
};
