import PropTypes from 'prop-types';
import React from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { ChevronRight } from '@dkrm/icons';
import { Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';

import styles from './styles';

export default class DrawerMenuItem extends React.PureComponent {
  static propTypes = {
    Component: PropTypes.element,
    Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.shape()]),
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    shouldHideRightIcon: PropTypes.bool,
    containerStyle: stylePropTypes,
    onPressWithoutCloseDrawer: PropTypes.func,
    handleDrawerMenuItemPress: PropTypes.func.isRequired,
    allowedUserGroups: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    Component: null,
    Icon: null,
    title: '',
    shouldHideRightIcon: false,
    containerStyle: null,
    onPressWithoutCloseDrawer: undefined,
    allowedUserGroups: [],
  };

  render() {
    const {
      Component,
      Icon,
      title,
      label,
      shouldHideRightIcon,
      containerStyle,
      onPressWithoutCloseDrawer,
      handleDrawerMenuItemPress,
    } = this.props;

    if (Component) {
      return <Component {...this.props} />;
    }

    return (
      <>
        {title && <Text style={styles.title}>{title}</Text>}
        <Button
          onPress={onPressWithoutCloseDrawer || handleDrawerMenuItemPress}
        >
          {() => (
            <View style={[styles.container, containerStyle]}>
              {!!Icon && <Icon theme="black" size={24} style={styles.icon} />}
              <Text style={styles.label}>{label}</Text>
              {!shouldHideRightIcon && <ChevronRight theme="black" size={24} />}
            </View>
          )}
        </Button>
      </>
    );
  }
}
