import React, { memo, useState, useCallback } from 'react';

import { StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { ArrowDropUp, ArrowDropDown } from '@dkrm/icons';
import cStyles from '@dkrm/general-libs/theme/styles';

import Text from '../Text';

type NavigationItem = {
  label: string;
  to?: string;
  isUsingAnchor?: boolean;
  trackerFunction?: (via: string, to?: string) => void;
};

export type NavigationItemProps = NavigationItem & {
  actionCloseDrawer: () => void;
  children?: Array<NavigationItem>;
};

const NavigationItem: React.FC<NavigationItemProps> = memo(
  ({
    actionCloseDrawer,
    label,
    to,
    isUsingAnchor,
    children,
    trackerFunction,
  }) => {
    const [shouldShowChildren, setShouldShowChildren] = useState(false);

    const handlePress = useCallback(() => {
      actionCloseDrawer();
      // eslint-disable-next-line no-unused-expressions
      trackerFunction?.('drawer-dnb');
    }, [actionCloseDrawer, trackerFunction]);

    const toggleShowChildren = useCallback(() => {
      setShouldShowChildren((prevState) => !prevState);
      // eslint-disable-next-line no-unused-expressions
      trackerFunction?.('drawer-dnb');
    }, [trackerFunction]);

    if (children) {
      return (
        <>
          <ButtonWrapper onPress={toggleShowChildren} style={s.navigation}>
            {() => (
              <>
                <Text theme="h5-black80" style={s.text}>
                  {label}
                </Text>
                {shouldShowChildren ? (
                  <ArrowDropUp size={24} theme="black" />
                ) : (
                  <ArrowDropDown size={24} theme="black" />
                )}
              </>
            )}
          </ButtonWrapper>
          {shouldShowChildren &&
            children.map((childNavigation) => (
              <ButtonWrapper
                to={childNavigation.to as string}
                linkProps={{ isUsingAnchor: childNavigation.isUsingAnchor }}
                onPress={() => {
                  actionCloseDrawer();
                  // eslint-disable-next-line no-unused-expressions
                  childNavigation?.trackerFunction?.(
                    'drawer-dnb',
                    childNavigation.to,
                  );
                }}
                style={s.navigation}
              >
                {() => (
                  <Text theme="m-black80" style={s.childText}>
                    {childNavigation.label}
                  </Text>
                )}
              </ButtonWrapper>
            ))}
        </>
      );
    }
    return (
      <ButtonWrapper
        to={to as string}
        linkProps={{ isUsingAnchor }}
        style={s.navigation}
        onPress={handlePress}
      >
        {() => (
          <Text theme="h5-black80" style={s.text}>
            {label}
          </Text>
        )}
      </ButtonWrapper>
    );
  },
);

export default NavigationItem;

const s = StyleSheet.create({
  navigation: {
    ...cStyles.paxl,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
  },
  text: {
    ...cStyles.flex1,
    marginLeft: 32,
  },
  childText: { marginLeft: 64 },
});
