import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';
import {
  ButtonWithText,
  ButtonWrapper,
  Colors,
  Image,
  StyleSheet,
  Text,
  View,
  cStyles,
} from '@dkrm/ui-kit-v2';
import { Close } from '@dkrm/ui-kit-v2/icons';

import { hideLogoutModal } from 'app-libs/redux_modules/auth';
import { getShouldShowLogoutModal } from 'app-libs/redux_modules/selectors/auth';

import { useLogout } from 'components/account/utils';

const K_IMAGE_DIMENSION = 240;
const K_IMAGE_URI =
  'https://media.dekoruma.com/frontend-empty-state/general-error.png';

const LogoutConfirmationModal = memo(() => {
  const dispatch = useDispatch();
  const shouldShowLogoutModal = useSelector(getShouldShowLogoutModal);

  const { actionLogout } = useLogout();

  const actionCloseModal = useCallback(() => {
    dispatch(hideLogoutModal());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    actionLogout();
    actionCloseModal();
  }, [actionCloseModal, actionLogout]);

  if (!shouldShowLogoutModal) return null;

  return (
    <ModalEnhanced isVisible style={s.modal} onBackdropPress={actionCloseModal}>
      <View style={s.container}>
        <View style={s.header}>
          <ButtonWrapper onPress={actionCloseModal}>
            <Close size={24} />
          </ButtonWrapper>
          <Text theme="h2" style={s.title}>
            Keluar
          </Text>
        </View>
        <View style={s.body}>
          <Image
            source={K_IMAGE_URI}
            alt="logout confirmation"
            style={s.image}
          />
          <Text theme="h2-teal500">Keluar dari akunmu?</Text>
          <Text theme="b3-neutral700" style={s.subText}>
            Kamu juga akan otomatis keluar dari semua aplikasi ekosistem Blibli
            Tiket.
          </Text>
          <View style={s.buttonGroup}>
            <ButtonWithText
              onPress={actionCloseModal}
              style={s.secondaryButton}
              title="Kembali"
              sizeType="large"
              themeType="secondary"
            />
            <ButtonWithText
              onPress={handleLogout}
              style={s.primaryButton}
              title="Keluar"
              sizeType="large"
              themeType="primary"
            />
          </View>
        </View>
      </View>
    </ModalEnhanced>
  );
});

const s = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
  },
  container: {
    ...cStyles.bgDefault,
  },
  image: {
    width: K_IMAGE_DIMENSION,
    height: K_IMAGE_DIMENSION,
  },
  subText: {
    ...cStyles.mt8,
    ...cStyles.tac,
  },
  buttonGroup: {
    ...cStyles.mt24,
    ...cStyles.mb32,
    ...cStyles.flexDirRow,
    ...cStyles.width100,
  },
  body: {
    ...cStyles.flexMiddle,
    ...cStyles.ph16,
  },
  header: {
    ...cStyles.flexDirRow,
    ...cStyles.bwbm,
    ...cStyles.ph16,
    paddingVertical: 20,
    borderColor: Colors.C_NEUTRAL_30,
  },
  title: {
    ...cStyles.flex1,
    ...cStyles.mr24,
    ...cStyles.tac,
  },
  secondaryButton: {
    ...cStyles.mr8,
    ...cStyles.flex1,
  },
  primaryButton: {
    ...cStyles.ml8,
    ...cStyles.flex1,
  },
});

export default LogoutConfirmationModal;
