import React, { memo } from 'react';

import { isIphone } from '@dkrm/general-libs/Utils/userAgentDetector';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, ResponsiveImage, Text, View } from '@dkrm/ui-kit-basic';
import { NAV_CLOSE_ROUND } from '@dkrm/ui-kit-basic/Icon/constants';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import { ButtonWithIcon, ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import { trackClickedAppBanner } from 'app-libs/trackers';

import { K_DEKORUMA_APP_ICON } from 'constants/assetConstants';
import {
  K_APPLE_STORE_LABEL,
  K_PLAY_STORE_LABEL,
} from 'constants/nativeAppConstants';
import { K_ROUTE_SMART_BANNER_TOP_BANNER } from 'constants/routeConstants';
import { K_TRACKING_PLACEMENT_TOP_BANNER } from 'constants/trackingConstants';

import styles from './styles';
import useAppBannerContext from './useAppBannerContext';

const K_DEKORUMA_APP_ICON_SIZE = 32;

const AppBanner = memo(() => {
  const { isAppBannerShown, dismissAppBanner } = useAppBannerContext();

  const getTitle = () => {
    return (
      <>
        <Text theme="h5-red" style={{ fontSize: 12 }}>
          Voucher 75Rb & Gratis Ongkir!*
        </Text>
        <Text theme="h5" style={{ fontSize: 12 }}>
          di Aplikasi Dekoruma
        </Text>
      </>
    );
  };

  const renderLogoAndText = () => (
    <View style={styles.textAndLogoContainer}>
      <ButtonWithIcon
        name={NAV_CLOSE_ROUND}
        onPress={dismissAppBanner}
        iconColor={Colors.C_BLACK_30}
        size={24}
        containerStyle={styles.closeButton}
      />
      <ResponsiveImage
        source={{
          uri: K_DEKORUMA_APP_ICON,
          width: K_DEKORUMA_APP_ICON_SIZE,
        }}
        widthProportion={1}
        heightProportion={1}
        style={styles.logo}
        resizeMode="contain"
      />
      <View style={cStyles.mrxl}>{getTitle()}</View>
    </View>
  );

  if (!isAppBannerShown) return null;
  const endpoint = isIphone() ? K_APPLE_STORE_LABEL : K_PLAY_STORE_LABEL;
  return (
    <View style={styles.bannerContainer}>
      {renderLogoAndText()}
      <ButtonWithText
        to={K_ROUTE_SMART_BANNER_TOP_BANNER}
        onPress={() =>
          trackClickedAppBanner(K_TRACKING_PLACEMENT_TOP_BANNER, endpoint)
        }
        title="Download"
        theme="tosca"
        themeType="primary"
        anchor
        textStyle={tStyles.body4}
        buttonStyle={styles.downloadButtonContainer}
      />
    </View>
  );
});

export default withRouter(AppBanner);
