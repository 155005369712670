import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  bannerContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.pvl,
    ...cStyles.phl,
    backgroundColor: colors.C_BLACK_5,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    ...cStyles.mrm,
    minWidth: 24,
    minHeight: 24,
  },
  downloadButtonContainer: {
    ...cStyles.phm,
    minWidth: 81,
    minHeight: 38,
  },
  textAndLogoContainer: {
    ...cStyles.flexDirRow,
    alignItems: 'center',
  },
  logo: {
    ...cStyles.flex1,
    ...cStyles.mrm,
    ...cStyles.brs,
    width: 32,
    height: 32,
  },
});
