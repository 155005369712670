import config from 'config';

import { getLang } from 'app-libs/libs/getLang';
import { trackClickedProductOffering } from 'app-libs/trackers';

import {
  K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN,
  K_PORTFOLIO_CATEGORY_KITCHEN_SET,
} from 'constants/designBuildConstants';
import {
  K_PORTFOLIO_STYLE_JAPANDI_NATURAL,
  K_PORTFOLIO_STYLE_JAPANDI_NATURAL_NAME,
  K_PORTFOLIO_STYLE_MODERN_CLASSIC,
  K_PORTFOLIO_STYLE_MODERN_CLASSIC_NAME,
  K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY,
  K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_NAME,
} from 'constants/portfolioConstants';
import {
  K_JAPANDI_CLASSIC_STYLE_INTERIOR_DESIGN_PATHNAME,
  K_JAPANDI_CLASSIC_STYLE_KITCHEN_SET_PATHNAME,
  K_JAPANDI_INDUSTRIAL_STYLE_INTERIOR_DESIGN_PATHNAME,
  K_JAPANDI_INDUSTRIAL_STYLE_KITCHEN_SET_PATHNAME,
  K_JAPANDI_MODERN_STYLE_INTERIOR_DESIGN_PATHNAME,
  K_JAPANDI_MODERN_STYLE_KITCHEN_SET_PATHNAME,
  K_JAPANDI_NATURAL_STYLE_INTERIOR_DESIGN_PATHNAME,
  K_JAPANDI_NATURAL_STYLE_KITCHEN_SET_PATHNAME,
  K_ROUTE_BUSINESS,
  K_ROUTE_INTERIOR_DESIGN_APARTMENT,
  K_ROUTE_INTERIOR_DESIGN_HOUSE,
  K_ROUTE_KITCHEN_SET,
  K_ROUTE_PORTFOLIO_LIST,
  K_ROUTE_WALK_IN_CLOSET,
} from 'constants/routeConstants';

export const K_PRODUCT_OFFERING_NAVIGATION_DATA = [
  {
    label: getLang('interior.kitchenSetProductOffering'),
    to: K_ROUTE_KITCHEN_SET,
    trackerFunction: trackClickedProductOffering,
  },
  {
    label: getLang('interior.houseProductOffering'),
    to: K_ROUTE_INTERIOR_DESIGN_HOUSE,
    trackerFunction: trackClickedProductOffering,
  },
  {
    label: getLang('interior.apartmentProductOffering'),
    to: K_ROUTE_INTERIOR_DESIGN_APARTMENT,
    trackerFunction: trackClickedProductOffering,
  },
  {
    label: getLang('interior.customWardrobeProductOffering'),
    to: K_ROUTE_WALK_IN_CLOSET,
    trackerFunction: trackClickedProductOffering,
  },
  {
    label: getLang('interior.businessProductOffering'),
    to: K_ROUTE_BUSINESS,
    trackerFunction: trackClickedProductOffering,
    isUsingAnchor: true,
  },
];

export const K_CONTENT_MAX_WIDTH = 1200;

export const K_PORTFOLIO_SECTION_THRESHOLD = 1120;
export const K_INSTALLMENT_SECTION_THRESHOLD = 965;

export const K_LOCATION_JAKARTA = 'Jakarta';
export const K_LOCATION_TANGERANG = 'Tangerang';
export const K_LOCATION_BEKASI = 'Bekasi';
export const K_LOCATION_DEPOK = 'Depok';
export const K_LOCATION_BOGOR = 'Bogor';
export const K_LOCATION_BANDUNG = 'Bandung';
export const K_LOCATION_CIMAHI = 'Cimahi';
export const K_LOCATION_SURABAYA = 'Surabaya';
export const K_LOCATION_SIDOARJO = 'Sidoarjo';
export const K_LOCATION_MEDAN = 'Medan';
export const K_LOCATION_SEMARANG = 'Semarang';
export const K_LOCATION_MALANG = 'Malang';
export const K_LOCATION_PEKANBARU = 'Pekanbaru';
export const K_LOCATION_BALI = 'Bali';
export const K_LOCATION_PALEMBANG = 'Palembang';

export const K_TOP_CITIES = [
  K_LOCATION_JAKARTA,
  K_LOCATION_TANGERANG,
  K_LOCATION_BEKASI,
  K_LOCATION_DEPOK,
  K_LOCATION_BOGOR,
  K_LOCATION_BANDUNG,
  K_LOCATION_CIMAHI,
  K_LOCATION_SURABAYA,
  K_LOCATION_SIDOARJO,
  K_LOCATION_MEDAN,
  K_LOCATION_SEMARANG,
  K_LOCATION_MALANG,
  K_LOCATION_PEKANBARU,
  K_LOCATION_BALI,
  K_LOCATION_PALEMBANG,
];

export const K_INTERIOR_DESIGN_STYLES_SIDEBAR_TITLE =
  'Pilihan Desain Interior Dekoruma';
export const K_KITCHEN_SET_STYLES_SIDEBAR_TITLE = 'Pilihan Desain Kitchen Set';

export const K_JAPANDI_NATURAL_STYLE_INTERIOR_DESIGN = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Japandi Natural`;
export const K_JAPANDI_CLASSIC_STYLE_INTERIOR_DESIGN = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Japandi Classic`;
export const K_JAPANDI_MODERN_STYLE_INTERIOR_DESIGN = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Japandi Modern`;
export const K_JAPANDI_INDUSTRIAL_STYLE_INTERIOR_DESIGN = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Japandi Industrial`;

export const K_JAPANDI_NATURAL_STYLE_KITCHEN_SET = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Japandi Natural`;
export const K_JAPANDI_CLASSIC_STYLE_KITCHEN_SET = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Japandi Classic`;
export const K_JAPANDI_MODERN_STYLE_KITCHEN_SET = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Japandi Modern`;
export const K_JAPANDI_INDUSTRIAL_STYLE_KITCHEN_SET = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Japandi Industrial`;

export const K_PATHNAME_BY_INTERIOR_DESIGN_STYLE = {
  [K_JAPANDI_NATURAL_STYLE_INTERIOR_DESIGN]:
    K_JAPANDI_NATURAL_STYLE_INTERIOR_DESIGN_PATHNAME,
  [K_JAPANDI_CLASSIC_STYLE_INTERIOR_DESIGN]:
    K_JAPANDI_CLASSIC_STYLE_INTERIOR_DESIGN_PATHNAME,
  [K_JAPANDI_MODERN_STYLE_INTERIOR_DESIGN]:
    K_JAPANDI_MODERN_STYLE_INTERIOR_DESIGN_PATHNAME,
  [K_JAPANDI_INDUSTRIAL_STYLE_INTERIOR_DESIGN]:
    K_JAPANDI_INDUSTRIAL_STYLE_INTERIOR_DESIGN_PATHNAME,
};

export const K_PATHNAME_BY_KITCHEN_SET_STYLE = {
  [K_JAPANDI_NATURAL_STYLE_KITCHEN_SET]:
    K_JAPANDI_NATURAL_STYLE_KITCHEN_SET_PATHNAME,
  [K_JAPANDI_CLASSIC_STYLE_KITCHEN_SET]:
    K_JAPANDI_CLASSIC_STYLE_KITCHEN_SET_PATHNAME,
  [K_JAPANDI_MODERN_STYLE_KITCHEN_SET]:
    K_JAPANDI_MODERN_STYLE_KITCHEN_SET_PATHNAME,
  [K_JAPANDI_INDUSTRIAL_STYLE_KITCHEN_SET]:
    K_JAPANDI_INDUSTRIAL_STYLE_KITCHEN_SET_PATHNAME,
};

export const K_JAPANDI_NATURAL_INTERIOR_DESIGN_META_TITLE = 'Natural';
export const K_JAPANDI_MODERN_INTERIOR_DESIGN_META_TITLE = 'Minimalis Modern';
export const K_JAPANDI_NATURAL_KITCHEN_SET_META_TITLE = 'Minimalis';
export const K_JAPANDI_MODERN_KITCHEN_SET_META_TITLE = 'Modern';
export const K_JAPANDI_CLASSIC_INTERIOR_DESIGN_META_TITLE = 'Classic';
export const K_JAPANDI_CLASSIC_KITCHEN_SET_META_TITLE = 'Klasik';
export const K_JAPANDI_INDUSTRIAL_CATEGORY_META_TITLE = 'Industrial';

export const K_USP_DATA = [
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/Japandi.png?auto=webp`,
    title: getLang('interior.usp1Title'),
    description: getLang('interior.usp1Description'),
    linkUrl: 'https://www.dekoruma.com/promosi/japandi-gaya-hidup-masa-kini',
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/36x+Cicilan.png?auto=webp`,
    title: getLang('interior.usp2Title'),
    description: getLang('interior.usp2Description'),
    linkUrl: 'https://www.dekoruma.com/promosi/cicilan-interior-36bulan',
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/Garansi+2tahun.png?auto=webp`,
    title: getLang('interior.usp3Title'),
    description: getLang('interior.usp3Description'),
    linkUrl: 'https://www.dekoruma.com/promosi/usp-garansi-2-tahun',
  },
];

export const K_USP_DATA_V2 = [
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/placeholder.png?auto=webp`,
    title: 'Personalized Custom Interior',
    description: 'Bebas personalisasi desain ruangan sesuai preferensi Anda',
    linkUrl: 'https://www.dekoruma.com/promosi/japandi-gaya-hidup-masa-kini',
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/placeholder.png?auto=webp`,
    title: 'Stress-Free Experience',
    description:
      'Seluruh tahapan desain hingga pembangunan dibantu oleh tim profesional kami',
    linkUrl: 'https://www.dekoruma.com/promosi/cicilan-interior-36bulan',
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/placeholder.png?auto=webp`,
    title: 'Easy Payment & Installment',
    description:
      'Proses pembayaran yang variatif serta tersedia alternatif cicilan',
    linkUrl: 'https://www.dekoruma.com/promosi/usp-garansi-2-tahun',
  },
];

export const K_PATHNAME_BY_INTERIOR_DESIGN_STYLE_V2 = {
  [K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_NAME]: `${K_ROUTE_PORTFOLIO_LIST}/${K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY}`,
  [K_PORTFOLIO_STYLE_MODERN_CLASSIC_NAME]: `${K_ROUTE_PORTFOLIO_LIST}/${K_PORTFOLIO_STYLE_MODERN_CLASSIC}`,
  [K_PORTFOLIO_STYLE_JAPANDI_NATURAL_NAME]: `${K_ROUTE_PORTFOLIO_LIST}/${K_PORTFOLIO_STYLE_JAPANDI_NATURAL}`,
};

export const K_PATHNAME_BY_KITCHEN_SET_STYLE_V2 = {
  [K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_NAME]: `${K_ROUTE_PORTFOLIO_LIST}${K_ROUTE_KITCHEN_SET}-${K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY}`,
  [K_PORTFOLIO_STYLE_MODERN_CLASSIC_NAME]: `${K_ROUTE_PORTFOLIO_LIST}${K_ROUTE_KITCHEN_SET}-${K_PORTFOLIO_STYLE_MODERN_CLASSIC}`,
  [K_PORTFOLIO_STYLE_JAPANDI_NATURAL_NAME]: `${K_ROUTE_PORTFOLIO_LIST}${K_ROUTE_KITCHEN_SET}-${K_PORTFOLIO_STYLE_JAPANDI_NATURAL}`,
};

export const K_MOBILE_USP_HOTSPOT_ID = '46c7a87d-952d-4745-88c7-89ecdba11a12';
