import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  container: {
    backgroundColor: colors.C_GREY_100,
  },

  drawerMenuContainer: {
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },

  downloadButtonContainer: {
    ...cStyles.bwam,
    ...cStyles.bcw,
    ...cStyles.brs,
    ...cStyles.phs,
    minWidth: 81,
    backgroundColor: colors.C_PRIMARY_TOSCA,
  },

  downloadAppContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.pvxl,
    ...cStyles.plxxl,
    ...cStyles.prl,
    justifyContent: 'space-between',
    backgroundColor: colors.C_PRIMARY_TOSCA,
    alignItems: 'center',
  },

  scannerButtonContainer: {
    ...cStyles.phxl,
    ...cStyles.bgw,
    ...cStyles.pbm,
  },

  downloadText: {
    ...tStyles.body4,
    color: colors.C_WHITE_100,
  },

  textAndLogoContainer: {
    ...cStyles.flexDirRow,
    alignItems: 'center',
  },

  logo: {
    ...cStyles.flex1,
    ...cStyles.mrm,
    ...cStyles.brs,
    width: 30,
    height: 30,
  },

  separator: {
    ...cStyles.mhxxl,
    ...cStyles.bwam,
    borderColor: colors.C_BLACK_5,
  },

  itemContainer: {
    ...cStyles.phxxl,
    ...cStyles.flexDirRow,
    ...cStyles.bgw,
    alignItems: 'center',
    height: 56,
  },
  itemLabel: {
    ...cStyles.mll,
    ...cStyles.flex1,
    ...tStyles.body2,
    color: colors.C_BLACK_85,
  },
});
