import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { useCallback, useContext } from 'react';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import { RouteContext } from 'app-libs/contexts/RouteContext';

type actionOpenUrlType = (
  url: string,
  router: unknown,
  options: {
    shouldOpenNewTab?: boolean;
    isUsingAnchor?: boolean;
  },
) => void;

const useActionOpenUrl = () => {
  const { setIsChangingRoute } = useContext(RouteContext);

  const actionOpenUrl: actionOpenUrlType = useCallback(
    (url, router, options = {}) => {
      if (options.isUsingAnchor === true) setIsChangingRoute(true);
      actionRouteActionOpenURL(url, router, options);
    },
    [setIsChangingRoute],
  );

  return actionOpenUrl;
};

export default useActionOpenUrl;

type Inner<Props> = Props & { actionOpenUrl: actionOpenUrlType };

export function withActionOpenUrl<Props>(
  BaseComponent: React.ComponentType<Inner<Props>>,
): React.FC<Props> {
  const WithActionOpenUrl: React.FC<Props> = (props) => {
    const actionOpenUrl = useActionOpenUrl();
    return <BaseComponent actionOpenUrl={actionOpenUrl} {...props} />;
  };

  hoistNonReactStatics(WithActionOpenUrl, BaseComponent);

  return WithActionOpenUrl;
}
