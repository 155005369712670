import { TextProps as RNTextProps } from 'react-native';

import React, { memo, useMemo, useCallback } from 'react';
import { Text as UIKitText } from '@dkrm/ui-kit-basic';

import colors from '../colors';

export type TextProps = RNTextProps & { theme?: string };

const Text: React.FC<TextProps> = memo(
  ({ theme = 'm-black', style, ...rest }) => {
    const getSizeStyle = useCallback((sizeTheme) => {
      switch (sizeTheme) {
        case 'h1':
          return {
            fontSize: 40,
            lineHeight: 56,
          };
        case 'h2':
          return {
            fontSize: 32,
            lineHeight: 44,
          };
        case 'h3':
          return {
            fontSize: 24,
            lineHeight: 32,
          };
        case 'h4':
          return {
            fontSize: 16,
            lineHeight: 24,
          };
        case 'h5':
          return {
            fontSize: 14,
            lineHeight: 20,
          };
        case 'h6':
          return {
            fontSize: 12,
            lineHeight: 16,
          };
        case 'xl':
          return {
            fontSize: 24,
            lineHeight: 32,
          };
        case 'l':
          return {
            fontSize: 16,
            lineHeight: 24,
          };
        case 'm':
          return {
            fontSize: 14,
            lineHeight: 20,
          };
        case 's':
          return {
            fontSize: 12,
            lineHeight: 16,
          };
        case 'xs':
          return {
            fontSize: 10,
            lineHeight: 12,
          };
        default:
          return {};
      }
    }, []);

    const getColorStyle = useCallback((colorTheme) => {
      switch (colorTheme) {
        case 'red60':
          return { color: colors.C_RED_60 };
        case 'teal60':
          return { color: colors.C_TEAL_60 };
        case 'darkteal20':
          return { color: colors.C_DARK_TEAL_20 };
        case 'yellow80':
          return { color: colors.C_YELLOW_80 };
        default:
          return {};
      }
    }, []);

    const styleFromTheme = useMemo(() => {
      const [sizeTheme, colorTheme] = theme.split('-');

      return {
        ...getSizeStyle(sizeTheme),
        ...getColorStyle(colorTheme),
      };
    }, [getColorStyle, getSizeStyle, theme]);

    return (
      <UIKitText theme={theme} style={[style, styleFromTheme]} {...rest} />
    );
  },
);

export default Text;
